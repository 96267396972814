import React, { useEffect, useState } from "react";
import styles from "./galleryCarousel.module.scss";
import { useSwipeable } from "react-swipeable";
import CloseButton from "../../../../../static/svg/CloseButton";
import ArrowLeft from "../../../../../static/svg/ArowLeft";
import ArrowRight from "../../../../../static/svg/ArrowRight";

const GalleryCarousel = ({ index, photos, closeGalleryCarousel }) => {
  const [activeIndex, setActiveIndex] = useState(index);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeIndex === photos.length - 1) {
        return;
      } else {
        setActiveIndex(activeIndex + 1);
      }
    },
    onSwipedRight: () => {
      if (activeIndex === 0) {
        return;
      } else {
        setActiveIndex(activeIndex - 1);
      }
    },
  });

  const clickLeft = () => {
    if (activeIndex === 0) {
      return;
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  const clickRight = () => {
    // const photosLength = props.isEdited ? props.editedPhotos.length : props.photos.length;

    if (activeIndex === photos.length - 1) {
      return;
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handleEscape = (event) => {
    if (event.key === "Escape") {
      closeGalleryCarousel();
    }
  };

  useEffect(() => {
    // Dodaj nasłuchiwacz podczas montowania komponentu
    document.addEventListener("keydown", handleEscape);

    // Usuń nasłuchiwacz podczas odmontowywania komponentu
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <div {...handlers} className={styles.container}>
      <div
        onClick={() => {
          closeGalleryCarousel();
        }}
        className={styles.closeButton}
      >
        <CloseButton color="#FFF" width="25" height="25" />
      </div>
      <div className={styles.photo}>
        <img src={photos[activeIndex].url} alt="" />
      </div>
      {/* -- NAVIGATION ARROWS -- */}
      <div className={styles.arrows}>
        <div onClick={clickLeft} className={styles.arrowLeft}>
          <ArrowLeft color={"white"} width="48" height="72" />
        </div>
        <div onClick={clickRight} className={styles.arrowRight}>
          <ArrowRight color={"white"} width="48" height="72" />
        </div>
      </div>
    </div>
  );
};

export default GalleryCarousel;
