import styles from "./galleryPage.module.scss";
import { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../../../store/AppContextProvider";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import Resizer from "react-image-file-resizer";

import Page from "../../../components/layout/page/Page";
import ConfirmationBox from "../../../components/layout/confirmationBox/ConfirmationBox";
import Controls from "./components/controls/Controls";
import LoadingBar from "../../../components/layout/LoadingBar";
import Alert from "../../../components/layout/Alert/Alert";
import ProgressBar from "../../../components/galleries/progressBar/ProgressBar";
import Gallery from "./components/gallery/Gallery";
import ReturnButton from "../../../components/layout/returnButton/ReturnButton";

const GalleryPage = (props) => {
  const [gallery, setGallery] = useState({});
  const [customer, setCustomer] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [photos, setPhotos] = useState([]);
  const [newPhotos, setNewPhotos] = useState([]);
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [editedPhotos, setEditedPhotos] = useState([]);
  const [uneditedPhotos, setUneditedPhotos] = useState([]);
  const [additionalPrintings, setAdditionalPrintings] = useState([]);
  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingPhotos, setLoadingPhotos] = useState(false);
  const [convertingPhotos, setConvertingPhotos] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [needConfirmation, setNeedConfirmation] = useState(false);
  const [question, setQuestion] = useState("");
  const [action, setAction] = useState();
  const [progressBar, setProgressBar] = useState(0);
  const [photosAmount, setPhotosAmount] = useState(0);
  const [showFilteredPhotos, setShowFilteredPhotos] = useState("all");
  const [showSortedPhotos, setShowSortedPhotos] = useState("");

  const { user, setUser } = useContext(UserContext);
  let { uploadedPhotoCount, setUploadedPhotoCount } = useContext(UserContext);

  let params = useParams();
  let navigate = useNavigate();
  const photosRef = useRef();
  const editedPhotosRef = useRef();

  const getCustomer = async () => {
    await axios.get(`/customers/${customerID}`).then((res) => {
      setCustomer(res.data);
    });
  };

  const getGallery = async () => {
    setLoading(true);
    await axios.get(`/galleries/${params.id}`).then((res) => {
      setGallery(res.data);
      setTitle(res.data.title);
      setStatus(res.data.status);
      setDate(res.data.date);
      setPhotos(res.data.photos);
      setCustomerID(res.data.customer);
      setAdditionalPrintings(res.data.additionalPrintings);

      // let editedPhotos = res.data.photos.filter((photo) => photo.edited === true);
      // setEditedPhotos(editedPhotos);

      setEditedPhotos(res.data.editedPhotos);

      let uneditedPhotos = res.data.photos.filter((photo) => photo.edited === false);
      setUneditedPhotos(uneditedPhotos);

      let selectedPhotos = res.data.photos.filter((photo) => photo.selected === true);
      setSelectedPhotos(selectedPhotos);
    });
    setLoading(false);
  };

  // Sprawdzam w czasie rzeczywistym szerokosc ekranu
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getCustomer();
    getGallery();
  }, [customerID]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handlePhotos = async (e) => {
    setLoadingPhotos(true);
    // Pobieram nazwę firmy, dodane zdjęcia oraz wszystkie zdjęcia z galerii
    const path = `${user.organization_name}`;
    const newPhotos = Array.from(e.target.files);
    console.log(newPhotos);
    // Ustalam ile zdjęć będzie przesyłanych
    setPhotosAmount(newPhotos.length);
    // Inicjalizuję formData i dodaję do niego nazwę firmy
    let formData = new FormData();
    formData.append("path", path);

    // Sprawdzam duplikaty zdjęć
    let duplicates = [];
    newPhotos.map((newPhoto) => {
      let newArray = photos.filter((photo) => newPhoto.name.replace(/\.[^/.]+$/, "") === photo.title);
      return (duplicates = duplicates.concat(newArray));
    });

    if (duplicates.length > 0) {
      setAlert({
        message: "Nie można dodać dwóch zdjęć o tej samej nazwie.",
        type: "info",
      });
      setLoadingPhotos(false);
      return;
    }

    // Sprawdzam czy nie przekroczono limitu ilości zdjęć - wycofano
    // if (newPhotos.length + photos.length > 500) {
    //   setAlert({
    //     message: "Przekroczono limit ilości zdjęć",

    //   });
    //   setLoadingPhotos(false);
    //   return;
    // }

    // Dla każdego nowego zdjęcia. Zmniejszam rozmiar i wysyłam na serwer.
    // Następnie dodaję wszystkie informacje o zdjęciu i zapisuję w bazie.
    for (const photo of newPhotos) {
      if (photo.type !== "image/jpeg" && photo.type !== "image/png") {
        setAlert({
          message: `Nieobsługiwany format zdjęcia ${photo.name}`,
          type: "info",
        });
        setLoadingPhotos(false);
      }
      // Zmnniejszam rozmiar zdjęcia
      const resizedPhoto = await resizeFile(photo);
      setUploadedPhotoCount(uploadedPhotoCount++);
      formData.append("photo", resizedPhoto);
      await axios
        .post("/upload", formData)
        .then((res) => {
          formData.delete("photo");
          const newPhoto = {
            id: res.data.id,
            url: res.data.url,
            title: photo.name.replace(/\.[^/.]+$/, ""),
            qt: 0,
            edited: false,
            selected: false,
            marked: false,
            showPrintings: false,
            printingsPrice: 0,
            printings: [
              {
                size: "10 x 15 cm",
                quantity: 0,
                price: additionalPrintings[0].value,
              },
              {
                size: "13 x 18 cm",
                quantity: 0,
                price: additionalPrintings[1].value,
              },
              {
                size: "15 x 21 cm",
                quantity: 0,
                price: additionalPrintings[2].value,
              },
              {
                size: "15 x 23 cm",
                quantity: 0,
                price: additionalPrintings[3].value,
              },
              {
                size: "20 x 30 cm",
                quantity: 0,
                price: additionalPrintings[4].value,
              },
              {
                size: "30 x 40 cm",
                quantity: 0,
                price: additionalPrintings[5].value,
              },
            ],
          };

          photos.push(newPhoto);
          const data = {
            title: title,
            date: date,
            photos: photos,
          };

          axios
            .put(`/galleries/${params.id}`, data)
            .then((res) => {})
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setTimeout(() => {
      getGallery();
      setLoadingPhotos(false);
      setUploadedPhotoCount(1);
      setPhotosAmount(0);
    }, 1000);
  };

  const handleEditedPhotos = async (e) => {
    setLoadingPhotos(true);
    // Pobieram nazwę firmy, dodane zdjęcia oraz wszystkie zdjęcia z galerii
    const path = `${user.organization_name}`;
    const newPhotos = Array.from(e.target.files);

    // Ustalam ile zdjęć będzie przesyłanych
    setPhotosAmount(newPhotos.length);

    // Sprawdzam czy nie przekroczono limitu ilości zdjęć
    if (newPhotos.length + editedPhotos.length > selectedPhotos.length) {
      setAlert({
        message: "Liczba gotowych zdjęć musi odpowiadać liczbie wybranych zdjęć",
        type: "info",
      });
      setLoadingPhotos(false);
      return;
    }

    // Inicjalizuję formData i dodaję do niego nazwę firmy
    let formData = new FormData();
    formData.append("path", path);

    for (const photo of newPhotos) {
      setUploadedPhotoCount(uploadedPhotoCount++);
      formData.append("photo", photo);
      await axios
        .post("/upload/edited", formData)
        .then((res) => {
          formData.delete("photo");
          const newPhoto = {
            id: res.data.id,
            url: res.data.url,
            title: photo.name.replace(/\.[^/.]+$/, ""),
            // qt: 0,
            edited: true,
            // selected: false,
            // marked: false,
            // showPrintings: false,
            // printingsPrice: 0,
            // printings: [
            //   {
            //     size: "10 x 15 cm",
            //     quantity: 0,
            //     price: additionalPrintings[0].value,
            //   },
            //   {
            //     size: "15 x 21 cm",
            //     quantity: 0,
            //     price: additionalPrintings[1].value,
            //   },
            //   {
            //     size: "20 x 30 cm",
            //     quantity: 0,
            //     price: additionalPrintings[2].value,
            //   },
            //   {
            //     size: "30 x 40 cm",
            //     quantity: 0,
            //     price: additionalPrintings[3].value,
            //   },
            // ],
          };

          editedPhotos.push(newPhoto);
          const data = {
            title: title,
            date: date,
            editedPhotos: editedPhotos,
          };

          axios
            .put(`/galleries/${params.id}`, data)
            .then((res) => {})
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((error) => {
          setAlert({
            message: error.response.data.message,
            type: "error",
          });
        });
    }

    setTimeout(() => {
      getGallery();
      setLoadingPhotos(false);
      setUploadedPhotoCount(1);
      setPhotosAmount(0);
    }, 1000);
  };

  const sendGallery = async () => {
    setLoading(true);
    const data = {
      organization_name: user.organization_name,
      login: customer.email,
      passwordUnsecure: customer.passwordUnsecure,
    };

    await axios
      .post("/galleries/send", data)
      .then((res) => {
        const gallery = {
          photos: photos,
          status: "sent",
        };
        axios
          .put(`/galleries/${params.id}`, gallery)
          .then((res) => {
            getGallery();
            setAlert({
              message: "Galeria została wysłana do klienta",
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  };

  const sendEditedGallery = async () => {
    setLoading(true);

    const data = {
      organization_name: user.organization_name,
      login: customer.email,
      passwordUnsecure: customer.passwordUnsecure,
    };

    await axios
      .post("/galleries/send-edited", data)
      .then((res) => {
        const gallery = {
          photos: photos,
          status: "edited",
        };
        axios
          .put(`/galleries/${params.id}`, gallery)
          .then((res) => {
            setAlert({
              message: "Galeria została wysłana do klienta",
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  };

  const confirmAction = (question, action) => {
    setQuestion(question);
    setNeedConfirmation(true);
    setAction(() => action);
  };

  const setFilter = (value) => {
    setShowFilteredPhotos(value);
    setDeletedPhotos([]);
  };

  const setSorter = (value) => {
    setShowSortedPhotos(value);
  };

  const showGallery = (data) => {
    setIsEdited(data);
  };

  return (
    <Page>
      <div className={styles.container}>
        {loading ? (
          <LoadingBar />
        ) : (
          <>
            <div className={styles.returnButton}>
              <ReturnButton title="Powrót do listy galerii" url="/galleries" />
            </div>
            <Controls
              gallery={gallery}
              customer={customer}
              confirmAction={confirmAction}
              sendGallery={sendGallery}
              sendEditedGallery={sendEditedGallery}
              handlePhotos={handlePhotos}
              handleEditedPhotos={handleEditedPhotos}
              editedPhotos={editedPhotos}
            />
            {loadingPhotos ? (
              <ProgressBar photosAmount={photosAmount} />
            ) : (
              <Gallery
                photos={photos}
                showFilteredPhotos={showFilteredPhotos}
                galleryStatus={gallery.status}
                setFilter={setFilter}
                setSorter={setSorter}
                showSortedPhotos={showSortedPhotos}
                uneditedPhotos={uneditedPhotos}
                editedPhotos={editedPhotos}
                getGallery={getGallery}
                isEdited={isEdited}
                showGallery={showGallery}
              />
            )}
          </>
        )}
      </div>

      {/* {isGalleryCarouselOpen && (
        <GalleryCarousel
          index={index}
          photos={photos}
          editedPhotos={editedPhotos}
          closeGalleryCarousel={closeGalleryCarousel}
        />
      )} */}

      {needConfirmation && (
        <ConfirmationBox
          positiveAction={() => action()}
          negativeAction={() => {
            setNeedConfirmation(false);
          }}
          question={question}
        />
      )}

      {alert.message ? (
        <Alert
          alert={alert}
          close={() =>
            setAlert({
              message: "",
              type: "",
            })
          }
        />
      ) : (
        ""
      )}
    </Page>
  );
};

export default GalleryPage;
