// import { useState } from "react";
// import styles from "./printingsChart.module.scss";
// import PrimaryButton from "../../../../../components/layout/primaryButton/PrimaryButton";

// const PrintingsChart = ({ photo, openPrintingsChart, closePrintingsChart, index, func }) => {
//   const [additionalPrintings0, setAdditionalPrintings0] = useState(photo.printings[0].quantity);
//   const [additionalPrintings1, setAdditionalPrintings1] = useState(photo.printings[1].quantity);
//   const [additionalPrintings2, setAdditionalPrintings2] = useState(photo.printings[2].quantity);
//   const [additionalPrintings3, setAdditionalPrintings3] = useState(photo.printings[3].quantity);
//   const [additionalPrintings4, setAdditionalPrintings4] = useState(photo.printings[4].quantity);
//   const [additionalPrintings5, setAdditionalPrintings5] = useState(photo.printings[5].quantity);

//   const choosePrintings = (event, index) => {
//     if (index === 0) {
//       setAdditionalPrintings0(parseInt(event.target.value));
//     } else if (index === 1) {
//       setAdditionalPrintings1(parseInt(event.target.value));
//     } else if (index === 2) {
//       setAdditionalPrintings2(parseInt(event.target.value));
//     } else if (index === 3) {
//       setAdditionalPrintings3(parseInt(event.target.value));
//     } else if (index === 4) {
//       setAdditionalPrintings4(parseInt(event.target.value));
//     } else if (index === 5) {
//       setAdditionalPrintings5(parseInt(event.target.value));
//     }
//   };

//   const passUpdatePrintings = (printing, index) => {
//     let updatedPhoto = photo;
//     updatedPhoto.printings[0].quantity = additionalPrintings0;
//     updatedPhoto.printings[1].quantity = additionalPrintings1;
//     updatedPhoto.printings[2].quantity = additionalPrintings2;
//     updatedPhoto.printings[3].quantity = additionalPrintings3;
//     updatedPhoto.printings[4].quantity = additionalPrintings4;
//     updatedPhoto.printings[5].quantity = additionalPrintings5;

//     updatedPhoto.printingsPrice =
//       updatedPhoto.printings[0].quantity * updatedPhoto.printings[0].price +
//       updatedPhoto.printings[1].quantity * updatedPhoto.printings[1].price +
//       updatedPhoto.printings[2].quantity * updatedPhoto.printings[2].price +
//       updatedPhoto.printings[3].quantity * updatedPhoto.printings[3].price +
//       updatedPhoto.printings[4].quantity * updatedPhoto.printings[4].price +
//       updatedPhoto.printings[5].quantity * updatedPhoto.printings[5].price;

//     func(updatedPhoto);
//   };

//   return (
//     <div className={styles.container}>
//       {photo.printings.map((printing, index) => (
//         <div key={printing._id} className={`${styles.printing} ${!printing.price ? styles.disabled : ""}`}>
//           <div className={styles.printingLabel}>
//             <p>{printing.size}</p>
//             <p>{printing.price} zł szt.</p>
//           </div>
//           <div className={styles.printingControls}>
//             <input
//               type="number"
//               min="0"
//               placeholder="Podaj ilość"
//               value={printing.quantity}
//               onChange={(event) => choosePrintings(event, index)}
//             />
//           </div>
//         </div>
//       ))}
//       <PrimaryButton
//         onClick={() => passUpdatePrintings()}
//         title="Zatwierdź"
//         color="#FFF"
//         backgroundColor="#0099F0"
//         borderColor="#0099F0"
//       />
//       <PrimaryButton
//         onClick={() => closePrintingsChart()}
//         title="Zamknij"
//         color="#0099F0"
//         backgroundColor="#FFF"
//         borderColor="#0099F0"
//       />
//     </div>
//   );
// };

// export default PrintingsChart;

import { useState } from "react";
import styles from "./printingsChart.module.scss";
import PrimaryButton from "../../../../../components/layout/primaryButton/PrimaryButton";

const PrintingsChart = ({ photo, openPrintingsChart, closePrintingsChart, func }) => {
  // Tablica stanu dla ilości
  const [quantities, setQuantities] = useState(photo.printings.map((printing) => printing.quantity));

  // Obsługa zmian w input
  const choosePrintings = (event, index) => {
    const newQuantities = [...quantities];
    newQuantities[index] = parseInt(event.target.value, 10) || 0; // Obsługa pustego pola
    setQuantities(newQuantities);
  };

  // Przekazanie zaktualizowanych ilości i cen
  const passUpdatePrintings = () => {
    const updatedPhoto = { ...photo };
    updatedPhoto.printings = updatedPhoto.printings.map((printing, index) => ({
      ...printing,
      quantity: quantities[index],
    }));

    updatedPhoto.printingsPrice = updatedPhoto.printings.reduce(
      (total, printing) => total + printing.quantity * printing.price,
      0
    );

    func(updatedPhoto);
  };

  const handleFocus = (event) => {
    event.target.select(); // Zaznaczenie całej zawartości po kliknięciu
  };

  return (
    <div className={styles.container}>
      {photo.printings.map((printing, index) => (
        <div key={printing._id} className={`${styles.printing} ${!printing.price ? styles.disabled : ""}`}>
          <div className={styles.printingLabel}>
            <p>{printing.size}</p>
            <p>{printing.price} zł szt.</p>
          </div>
          <div className={styles.printingControls}>
            <input
              type="number"
              min="0"
              placeholder="Podaj ilość"
              value={quantities[index]}
              onChange={(event) => choosePrintings(event, index)}
              onFocus={handleFocus}
            />
          </div>
        </div>
      ))}
      <PrimaryButton
        onClick={passUpdatePrintings}
        title="Zatwierdź"
        color="#FFF"
        backgroundColor="#0099F0"
        borderColor="#0099F0"
      />
      <PrimaryButton
        onClick={closePrintingsChart}
        title="Zamknij"
        color="#0099F0"
        backgroundColor="#FFF"
        borderColor="#0099F0"
      />
    </div>
  );
};

export default PrintingsChart;
