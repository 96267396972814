import { useState, useRef, useEffect, useContext } from "react";
import { UserContext } from "../../../store/AppContextProvider";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

import styles from "./newGallery.module.scss";
import axios from "axios";

import Page from "../../../components/layout/page/Page";
import FormInput from "../../../components/layout/Form/form-input/FormInput";
import FormSelect from "../../../components/layout/Form/form-select/FormSelect";
import PrimaryButton from "../../../components/layout/primaryButton/PrimaryButton";
import Alert from "../../../components/layout/Alert/Alert";
import UserIcon from "../../../static/svg/UserIcon";

const NewGalleryPage = (props) => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [price, setPrice] = useState(0);
  const [paid, setPaid] = useState(0);
  const [type, setType] = useState("");
  const [shots, setShots] = useState(0);
  const [shotPrice, setShotPrice] = useState(0);
  const [printings, setPrintings] = useState(0);
  const [ifPrintings, setIfPrintings] = useState(true);
  const [price1015, setPrice1015] = useState(0);
  const [price1318, setPrice1318] = useState(0);
  const [price1521, setPrice1521] = useState(0);
  const [price1523, setPrice1523] = useState(0);
  const [price2030, setPrice2030] = useState(0);
  const [price3040, setPrice3040] = useState(0);
  const [freePrintings1015, setFreePrintings1015] = useState(0);
  const [freePrintings1318, setFreePrintings1318] = useState(0);
  const [freePrintings1521, setFreePrintings1521] = useState(0);
  const [freePrintings1523, setFreePrintings1523] = useState(0);
  const [freePrintings2030, setFreePrintings2030] = useState(0);
  const [freePrintings3040, setFreePrintings3040] = useState(0);
  const [customer, setCustomer] = useState("");
  const [customers, setCustomers] = useState([]);
  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const PRINTINGS_PRICE = [
    {
      size: "10 x 15",
      value: price1015,
      setValue: setPrice1015,
      freePrintings: freePrintings1015,
      setFreePrintings: setFreePrintings1015,
    },
    {
      size: "13 x 18",
      value: price1318,
      setValue: setPrice1318,
      freePrintings: freePrintings1318,
      setFreePrintings: setFreePrintings1318,
    },
    {
      size: "15 x 21",
      value: price1521,
      setValue: setPrice1521,
      freePrintings: freePrintings1521,
      setFreePrintings: setFreePrintings1521,
    },
    {
      size: "15 x 23",
      value: price1523,
      setValue: setPrice1523,
      freePrintings: freePrintings1523,
      setFreePrintings: setFreePrintings1523,
    },
    {
      size: "20 x 30",
      value: price2030,
      setValue: setPrice2030,
      freePrintings: freePrintings2030,
      setFreePrintings: setFreePrintings2030,
    },
    {
      size: "30 x 40",
      value: price3040,
      setValue: setPrice3040,
      freePrintings: freePrintings3040,
      setFreePrintings: setFreePrintings3040,
    },
  ];

  const SESSION_TYPES = [
    {
      _id: "rodzinna",
      name: "rodzinna",
    },
    {
      _id: "dziecięca",
      name: "dziecięca",
    },
    {
      _id: "noworodkowa",
      name: "noworodkowa",
    },
    {
      _id: "ciążowa",
      name: "ciążowa",
    },
    {
      _id: "reportaż",
      name: "reportaż",
    },
    {
      _id: "inna",
      name: "inna",
    },
  ];

  useEffect(() => {
    if (user.available_sessions === 0) {
      setAlert({ message: "Nie masz już dostępnych sesji. Skontaktuj się z nami", type: "info" });
    }

    getCustomers();
  }, []);

  const getCustomers = async () => {
    await axios.get("/customers").then((res) => {
      setCustomers(res.data);
      if (searchParams.get("id")) {
        setCustomer(searchParams.get("id"));
      } else {
        setCustomer("");
      }
    });
  };

  const createGallery = async (e) => {
    e.preventDefault();

    const additionalPrintings = [
      {
        size: "10 x 15",
        freePrintings: freePrintings1015,
        value: price1015,
      },
      {
        size: "13 x 18",
        freePrintings: freePrintings1318,
        value: price1318,
      },
      {
        size: "15 x 21",
        freePrintings: freePrintings1521,
        value: price1521,
      },
      {
        size: "15 x 23",
        freePrintings: freePrintings1523,
        value: price1523,
      },
      {
        size: "20 x 30",
        freePrintings: freePrintings2030,
        value: price2030,
      },
      {
        size: "30 x 40",
        freePrintings: freePrintings3040,
        value: price3040,
      },
    ];

    if (user.available_sessions === 0) {
      return setAlert({ message: "Nie masz już dostępnych sesji. Skontaktuj się z nami", type: "info" });
    } else {
      await axios
        .get(`/customers/${customer}`)
        .then((res) => {
          // Zbieram wprowadzone dane
          let data = {
            title: title,
            date: date,
            customer: customer,
            customerName: res.data.name,
            type: type,
            price: price,
            paid: paid,
            shotsQt: shots,
            additionalShotPrice: shotPrice,
            ifPrintings: additionalPrintings.some((item) => item.value > 0),
            additionalPrintings: additionalPrintings,
          };

          // Wysyłam zebrane dane
          axios
            .post("/galleries", data)
            .then((res) => {
              setUser({
                ...user,
                available_sessions: user.available_sessions - 1,
              });
              navigate(`/galleries/${res.data._id}`);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleFocus = (event) => {
    event.target.select(); // Zaznaczenie całej zawartości po kliknięciu
  };

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.newGalleryHeader}>
          <h3>Nowa galeria</h3>
          <p>Wypełnij formularz, zdjęcia dodasz w kolejnym kroku </p>
        </div>
        <form className={styles.newGalleryForm} onSubmit={createGallery}>
          <div className={styles.data}>
            <div className={styles.primaryData}>
              <div className={styles.selectClient}>
                <FormSelect
                  onChange={(e) => setCustomer(e.target.value)}
                  name="customer"
                  id="customer"
                  label="Nazwa klienta"
                  selection="Wybierz klienta"
                  initialOption=""
                  errorMessage="Klient jest wymagany."
                  value={customer}
                  values={customers}
                  pattern=".{1,}"
                  isRequired={true}
                  required
                  icon={<UserIcon color="#191919" />}
                  disabled={user.available_sessions === 0}
                />

                <NavLink to="/customers/new-customer" state={{ from: "galleries/new-gallery" }}>
                  <div className={styles.addClientButton}>
                    <UserIcon color="#0099F0" />
                    <p>Dodaj klienta</p>
                  </div>
                </NavLink>
              </div>

              <div className={styles.selectTitle}>
                <FormInput
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={customer ? false : true}
                  value={title}
                  type="text"
                  label="Nazwa galerii"
                  placeholder="Podaj nazwę galerii"
                  name="title"
                  pattern=".{1,50}"
                  errorMessage="Nazwa galerii jest wymagana i może zawierać maksymalnie 50 znaków."
                  required
                  isRequired={true}
                />
              </div>

              <div className={styles.selectDateAndType}>
                <div className={styles.dateInput}>
                  <FormInput
                    onChange={(e) => setDate(e.target.value)}
                    disabled={customer ? false : true}
                    value={date}
                    label="Data wydarzenia"
                    name="date"
                    type="date"
                    errorMessage="Data wydarzenia jest wymagana."
                    required
                    isRequired={true}
                  />
                </div>
                <div className={styles.typeSelect}>
                  <FormSelect
                    onChange={(e) => setType(e.target.value)}
                    disabled={customer ? false : true}
                    name="type"
                    id="type"
                    label="Rodzaj sesji"
                    values={SESSION_TYPES}
                    selection=""
                    pattern=".{1,}"
                    errorMessage="Rodzaj sesji jest wymagany."
                    required
                    isRequired={true}
                  />
                </div>
              </div>

              <div className={styles.selectPrice}>
                <div className={styles.priceSelect}>
                  <FormInput
                    onChange={(e) => setPrice(e.target.value)}
                    disabled={customer ? false : true}
                    value={price || ""}
                    label="Cena sesji (zł)"
                    name="price"
                    min="0"
                    type="number"
                    errorMessage="Cena sesji jest wymagana a wartość nie może być mniejsza niż 0."
                    required
                    isRequired={true}
                  />
                </div>
                <div className={styles.paidSelect}>
                  <FormInput
                    onChange={(e) => setPaid(e.target.value)}
                    disabled={customer ? false : true}
                    value={paid || ""}
                    label="Zapłacono (zł)"
                    name="paid"
                    id="paid"
                    min="0"
                    max={price}
                    type="number"
                    errorMessage="Wartość nie może być większa od ceny sesji i mniejsza niż 0."
                    required
                    isRequired={true}
                  />
                </div>
              </div>

              <div className={styles.selectShots}>
                <div className={styles.shotsSelect}>
                  <FormInput
                    onChange={(e) => setShots(e.target.value)}
                    disabled={customer ? false : true}
                    value={shots || ""}
                    label="Ilość ujęć w cenie sesji"
                    name="shots"
                    id="shots"
                    min="0"
                    type="number"
                    errorMessage="Ilość ujęć jest wymagana a wartość nie może być mniejsza niż 0."
                    required
                    isRequired={true}
                  />
                </div>
                <div className={styles.addShotsSelect}>
                  <FormInput
                    onChange={(e) => setShotPrice(e.target.value)}
                    disabled={customer ? false : true}
                    value={shotPrice || ""}
                    label="Cena dodatkowego ujęcia (zł)"
                    name="shotPrice"
                    id="shotPrice"
                    min="0"
                    type="number"
                    errorMessage="Cena dodatkowego ujęcia jest wymagana a wartość nie może być mniejsza niż 0."
                    required
                    isRequired={true}
                  />
                </div>
              </div>
            </div>
            <div className={styles.printingsData}>
              <h4>Wydruki</h4>
              <p>Jeśli sprzedajesz wydruki podaj cenę za 1 sztukę przy każdym sprzedawanym rozmiarze</p>
              <div className={styles.selectPrintings}>
                {PRINTINGS_PRICE.map((print) => (
                  <div key={print.size}>
                    <div>
                      <p style={{ fontWeight: "500" }}>{print.size} cm</p>
                    </div>
                    <div className={styles.printSelect}>
                      <input
                        onChange={(e) => {
                          print.setValue(e.target.value);
                        }}
                        disabled={customer ? false : true}
                        value={print.value}
                        name="value"
                        id="value"
                        type="number"
                        min="0"
                        onFocus={handleFocus}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.confirmBox}>
            <PrimaryButton
              className={styles.confirmButton}
              title="Stwórz galerię"
              backgroundColor="#0099F0"
              border="4px"
              color="#FFF"
            />
          </div>
        </form>
      </div>
      {alert.message ? <Alert alert={alert} close={() => setAlert({ message: "", type: "" })} /> : ""}
    </Page>
  );
};

export default NewGalleryPage;
