import styles from "./profileSettings.module.scss";
import FormInput from "../../../../components/layout/Form/form-input/FormInput";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../../components/layout/primaryButton/PrimaryButton";
import axios from "axios";

const ProfileSettings = ({ user, setAlert }) => {
  const [userProfile, setUserProfile] = useState({
    name: "" || user.name,
    surname: "" || user.surname,
    phone: "" || user.phone,
    webpage: "" || user.webpage,
    facebook: "" || user.facebook,
    instagram: "" || user.instagram,
    organization_name: "" || user.organization_name,
    address: "" || user.address,
    city: "" || user.city,
    zipcode: "" || user.zipcode,
    taxNumber: "" || user.taxNumber,
  });

  useEffect(() => {
    // getUser();
  }, []);

  const handleChangeProfile = async (e) => {
    e.preventDefault();

    await axios
      .put(`/users`, userProfile)
      .then((res) => {
        setAlert({
          message: "Zmiany zostały zapisane.",
          type: "success",
        });
      })
      .catch((error) =>
        setAlert({
          message: error.response.data.message,
          type: "error",
        })
      );
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleChangeProfile}>
        <div className={styles.primaryData}>
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, name: e.target.value };
              })
            }
            value={userProfile.name}
            type="text"
            label="Imię"
            name="name"
            pattern=".{1,50}"
            errorMessage="Imię jest wymagane i może zawierać maksymalnie 50 znaków."
            required
            isRequired={true}
          />
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, surname: e.target.value };
              })
            }
            value={userProfile.surname}
            type="text"
            label="Nazwisko"
            name="surname"
            pattern=".{1,50}"
            errorMessage="Nazwisko jest wymagane i może zawierać maksymalnie 50 znaków."
            required
            isRequired={true}
          />
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, phone: e.target.value };
              })
            }
            value={userProfile.phone}
            type="number"
            label="Telefon kontaktowy"
            name="phone"
            pattern="^\d{9}$"
            errorMessage="Numer telefonu jest wymagany."
            required
            isRequired={true}
          />
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, webpage: e.target.value };
              })
            }
            value={userProfile.webpage}
            type="text"
            label="Adres strony internetowej"
            name="webpage"
            errorMessage="Podaj poprawny adres strony internetowej."
          />
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, facebook: e.target.value };
              })
            }
            value={userProfile.facebook}
            type="text"
            label="Facebook"
            name="facebook"
            errorMessage="Podaj poprawny adres strony internetowej."
          />
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, instagram: e.target.value };
              })
            }
            value={userProfile.instagram}
            type="text"
            label="Instagram"
            name="instagram"
            errorMessage="Podaj poprawny adres strony internetowej."
          />
        </div>
        <div className={styles.secondaryData}>
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, organization_name: e.target.value };
              })
            }
            value={userProfile.organization_name}
            type="text"
            label="Nazwa firmy"
            name="organization_name"
            pattern="^[a-zA-Z0-9AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż !@#$%&*]{3,50}$"
            errorMessage="Nazwa firmy jest wymagana i musi zawierać od 3 do 50 znaków."
            required
            isRequired={true}
          />
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, address: e.target.value };
              })
            }
            value={userProfile.address}
            type="text"
            label="Adres"
            name="address"
            pattern="^[a-zA-Z0-9AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż !@#$%&*]{3,50}$"
            errorMessage="Adres jest wymagany."
            required
            isRequired={true}
          />
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, city: e.target.value };
              })
            }
            value={userProfile.city}
            type="text"
            label="Miasto"
            name="city"
            pattern="^[a-zA-Z0-9AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż !@#$%&*]{3,50}$"
            errorMessage="Miasto jest wymagane."
            required
            isRequired={true}
          />
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, zipcode: e.target.value };
              })
            }
            value={userProfile.zipcode}
            type="text"
            label="Kod pocztowy"
            name="zipcode"
            pattern="^\d{2}-\d{3}$"
            errorMessage="Kod pocztowy jest wymagany."
            required
            isRequired={true}
          />
          <FormInput
            onChange={(e) =>
              setUserProfile((prevState) => {
                return { ...prevState, taxNumber: e.target.value };
              })
            }
            value={userProfile.taxNumber}
            type="text"
            label="NIP"
            name="taxNumber"
          />
          <div className={styles.buttons}>
            <PrimaryButton title="Zapisz zmiany" backgroundColor="#0099F0" borderColor="#0099F0" color="#FFF" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileSettings;
