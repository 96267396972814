import { useState } from "react";
import styles from "./photos.module.scss";
import Photo from "../photo/Photo";

const Photos = ({ photos, galleryStatus, handleDeletedPhotos, getGallery, openGalleryCarousel, isEdited }) => {
  return (
    <div className={styles.container}>
      {photos.map((photo, index) => (
        <Photo
          key={index}
          photo={photo}
          photos={photos}
          index={index}
          galleryStatus={galleryStatus}
          getGallery={getGallery}
          handleDeletedPhotos={handleDeletedPhotos}
          openGalleryCarousel={openGalleryCarousel}
        />
      ))}
    </div>
  );
};

export default Photos;
